<template>
  <my-event-list-container :city-id="cityId" :style-id="styleId" :has-filter="false" />
</template>

<script>
import { getTodayISO } from "@/datetimes";
import MyEventListContainer from "@/modules/event/list/MyEventListContainer";
import { eventsStore, usersStore } from "@/store";

const loadEvents = (to, from, next) => {
  const tab = ~["going", "organizing"].indexOf(to.query.tab) ? to.query.tab : "going";

  return eventsStore
    .loadEvents({
      userIdGoing: tab === "going" ? usersStore.user.id : undefined,
      createdById: tab === "organizing" ? usersStore.user.id : undefined,
      endDateGte: getTodayISO(),
      sortBy: to.query.sortBy,
      sortByOrder: to.query.sortByOrder,
      limit: to.query.limit,
      offset: to.query.offset,
    })
    .then(() => next());
};

export default {
  name: "MyEventListView",
  metaInfo() {
    return {
      title: this.$t("viewsMeta.myEventList.title"),
    };
  },
  components: { MyEventListContainer },
  beforeRouteEnter: loadEvents,
  beforeRouteUpdate: loadEvents,
};
</script>
