























import "reflect-metadata";

import { Component, Vue, Watch } from "vue-property-decorator";

import { Api } from "@/constants";
import { GOING_CLICKED } from "@/modules/event/event-handlers";
import EventListItem from "@/modules/event/list/components/EventListItem.vue";
import { eventsStore } from "@/store";

@Component({ components: { EventListItem } })
export default class MyEventListContainer extends Vue {
  private activeTab = "";

  created() {
    // @ts-ignore
    this.activeTab = ["going", "organizing"].includes(this.$route.query.tab)
      ? this.$route.query.tab
      : "going";
  }

  @Watch("activeTab")
  async onActiveTabChanged(val: string) {
    await this.$router.replace({
      query: {
        ...this.$router.currentRoute.query,
        tab: val,
      },
    });
  }

  get apiLoadingEvents() {
    return eventsStore.loading(Api.EventsLoadEvents);
  }

  get handlerGoingClicked() {
    return GOING_CLICKED;
  }

  get getApiLoadingEventGoing() {
    return (eventId: string) => {
      return eventsStore.loading(`${Api.EventsUpdateEventGoing}--${eventId}`);
    };
  }

  get events() {
    return eventsStore.events;
  }
}
